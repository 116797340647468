export const HANDLE_COLLAPSE_SIDE_MENU = 'HANDLE_COLLAPSE_SIDE_MENU';
export const HANDLE_SHOW_AUTH_MODAL = 'HANDLE_SHOW_AUTH_MODAL';
export const HANDLE_SHOW_REGISTRATION_MODAL = 'HANDLE_SHOW_REGISTRATION_MODAL';
export const CLOSE_AUTHORIZATION_MODALS = 'CLOSE_AUTHORIZATION_MODALS';
export const SET_NOTIFICATION_FORM = 'SET_NOTIFICATION_FORM';
export const SET_NOTIFICATION = 'SET_NOTIFICATION';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const SET_USER_DATA = 'SET_USER_DATA';

export const DELETE_USER_PENDING = 'DELETE_USER_PENDING';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAILURE = 'DELETE_USER_FAILURE';

export const GET_USER_LIST_PENDING = 'GET_USER_LIST_PENDING';
export const GET_USER_LIST_SUCCESS = 'GET_USER_LIST_SUCCESS';
export const GET_USER_LIST_FAILURE = 'GET_USER_LIST_FAILURE';

export const GET_LOADING_SUCCESS = 'GET_LOADING_SUCCESS';
export const GET_LOADING_PENDING = 'GET_LOADING_PENDING';

export const AUTH_PENDING = 'AUTH_PENDING';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAILURE = 'AUTH_FAILURE';

export const REGISTRATION_PENDING = 'REGISTRATION_PENDING';
export const REGISTRATION_SUCCESS = 'REGISTRATION_SUCCESS';
export const REGISTRATION_FAILURE = 'REGISTRATION_FAILURE';

export const GET_AUTHORIZATION_STATUS_PENDING =
  'GET_AUTHORIZATION_STATUS_PENDING';
export const GET_AUTHORIZATION_STATUS_SUCCESS =
  'GET_AUTHORIZATION_STATUS_SUCCESS';
export const GET_AUTHORIZATION_STATUS_FAILURE =
  'GET_AUTHORIZATION_STATUS_FAILURE';

export const GET_GOOGLE_AUTHORIZATION_PENDING =
  'GET_GOOGLE_AUTHORIZATION_PENDING';
export const GET_GOOGLE_AUTHORIZATION_SUCCESS =
  'GET_GOOGLE_AUTHORIZATION_SUCCESS';
export const GET_GOOGLE_AUTHORIZATION_FAILURE =
  'GET_GOOGLE_AUTHORIZATION_FAILURE';

export const HANDLE_REFRESH = 'HANDLE_REFRESH';
export const HANDLE_REFRESH_SUCCESS = 'HANDLE_REFRESH_SUCCESS';
export const HANDLE_REFRESH_FAILURE = 'HANDLE_REFRESH_FAILURE';

export const GET_USER_ORDERS_PENDING = 'GET_USER_ORDERS_PENDING';
export const GET_USER_ORDERS_SUCCESS = 'GET_USER_ORDERS_SUCCESS';
export const GET_USER_ORDERS_FAILURE = 'GET_USER_ORDERS_FAILURE';

export const GET_BACKUP_USER_ORDERS_PENDING = 'GET_BACKUP_USER_ORDERS_PENDING';
export const GET_BACKUP_USER_ORDERS_FAILURE = 'GET_BACKUP_USER_ORDERS_FAILURE';

export const SET_USER_ORDER_PENDING = 'SET_USER_ORDER_PENDING';
export const SET_USER_ORDER_SUCCESS = 'SET_USER_ORDER_SUCCESS';
export const SET_USER_ORDER_FAILURE = 'SET_USER_ORDER_FAILURE';

export const DELETE_USER_ORDER_PENDING = 'DELETE_USER_ORDER_PENDING';
export const DELETE_USER_ORDER_SUCCESS = 'DELETE_USER_ORDER_SUCCESS';
export const DELETE_USER_ORDER_FAILURE = 'DELETE_USER_ORDER_FAILURE';

export const SET_USER_ORDERS_PENDING = 'SET_USER_ORDERS_PENDING';
export const SET_USER_HISTORY = 'SET_USER_HISTORY';

export const GET_COIN_LIST_PENDING = 'GET_COIN_LIST_PENDING';
export const GET_COIN_LIST_SUCCESS = 'GET_COIN_LIST_SUCCESS';
export const GET_COIN_LIST_FAILURE = 'GET_COIN_LIST_FAILURE';
export const SELECT_COINS = 'SELECT_COINS';
export const GENERATE_COIN_CARDS = 'GENERATE_COIN_CARDS';
export const GENERATE_COIN_CARDS_SUCCESS = 'GENERATE_COIN_CARDS_SUCCESS';
export const GENERATE_COIN_CARDS_FAILURE = 'GENERATE_COIN_CARDS_FAILURE';

export const CHANGE_BUDGET = 'CHANGE_BUDGET';
export const CHANGE_GAP = 'CHANGE_GAP';
export const SET_BANK_VALUE = 'SET_BANK_VALUE';
export const SET_USER_NEW_PLACE = 'SET_USER_NEW_PLACE';
export const SET_USER_NEW_PLACE_SUCCESS = 'SET_USER_NEW_PLACE_SUCCESS';
export const SET_USER_NEW_PLACE_FAILURE = 'SET_USER_NEW_PLACE_FAILURE';
export const GET_USER_PLACE_LIST_PENDING = 'GET_USER_PLACE_LIST_PENDING';
export const GET_USER_PLACE_LIST_SUCCESS = 'GET_USER_PLACE_LIST_SUCCESS';
export const GET_USER_PLACE_LIST_FAILURE = 'GET_USER_PLACE_LIST_FAILURE';
export const CHANGE_IS_PERCENT = 'CHANGE_IS_PERCENT';
export const CHANGE_LIST_PERCENT = 'CHANGE_LIST_PERCENT';
export const HANDLE_COIN_HOLD_PLACE = 'HANDLE_COIN_HOLD_PLACE';

export const UPDATE_USER_ORDER_PENDING = 'UPDATE_USER_ORDER_PENDING';
export const UPDATE_USER_ORDER_SUCCESS = 'UPDATE_USER_ORDER_SUCCESS';
export const UPDATE_USER_ORDER_FAILURE = 'UPDATE_USER_ORDER_FAILURE';

// Получение данных инвесторов
export const GET_INVESTORS_DATA_PENDING = 'GET_INVESTORS_DATA_PENDING';
export const GET_INVESTORS_DATA_SUCCESS = 'GET_INVESTORS_DATA_SUCCESS';
export const GET_INVESTORS_DATA_FAILURE = 'GET_INVESTORS_DATA_FAILURE';

// Добавление нового инвестора
export const ADD_NEW_INVESTOR_PENDING = 'ADD_NEW_INVESTOR_PENDING';
export const ADD_NEW_INVESTOR_SUCCESS = 'ADD_NEW_INVESTOR_SUCCESS';
export const ADD_NEW_INVESTOR_FAILURE = 'ADD_NEW_INVESTOR_FAILURE';

// Обновление средств инвестора
export const UPDATE_INVESTOR_FUNDS_PENDING = 'UPDATE_INVESTOR_FUNDS_PENDING';
export const UPDATE_INVESTOR_FUNDS_SUCCESS = 'UPDATE_INVESTOR_FUNDS_SUCCESS';
export const UPDATE_INVESTOR_FUNDS_FAILURE = 'UPDATE_INVESTOR_FUNDS_FAILURE';
